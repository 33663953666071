* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo do Corpo da Página */
body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(240, 229, 215);
  overflow: scroll;
}

body::-webkit-scrollbar { 
  display: none;
}

h1 {
  font-size: 36px;
  font-family: 'Istok Web', sans-serif;
  text-align: center;
  padding-top: 50px;
color:black;
}

h2 {
  font-size: 24px;
  font-family: 'Istok Web', sans-serif;
  color:black;
}

h4 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:black;
}

label {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
color:black;
}

span {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
color:black;
}

p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
color: black;
}

h1, h2, h4, label,span, p {
  line-height: 1.6;
}



@media screen and (max-width: 991px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  label {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  span {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  p {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 26px;
    padding: 30px 0 0 0;
  }
  
  h2 {
    font-size: 20px;
  }


}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 22px;

  }
  h2 {
    font-size: 18px;
  }

}

@media screen and (max-width: 375px) {
  body {
    background-color:#f8ecc6;
  ;
  }
}




